import { Component, inject, OnInit, signal } from '@angular/core';
import { LoyaltyClubService } from '@modules/club/loyalty-club.service';
import { PageComponent } from '@shared/components/ui/page/page.component';
import { DividerComponent } from '@shared/components/ui/divider/divider.component';
import { CardComponent } from '@shared/components/ui/card/card.component';
import { ClubFeatureComponent } from '@modules/club/components/club-feature/club-feature.component';
import { StatusBannerComponent } from '@modules/club/components/status-banner/status-banner.component';
import { InviteBannerComponent } from '@modules/club/components/invite-banner/invite-banner.component';
import { IconComponent } from '@shared/components/icon/icon.component';
import { ClubTransactionsListComponent } from '@modules/club/components/club-transactions-list/club-transactions-list.component';
import { LoadingComponent } from '@shared/components/loading/loading.component';
import {
  LoyaltyStatus,
  LoyaltyStatusDto,
} from '@modules/club/loyalty-club.types';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { TranslocoPipe } from '@jsverse/transloco';
import { UserService } from '@app/core/services/user.service';
import { StatusOverviewComponent } from './components/status-overview/status-overview.component';
import { ActivatedRoute, Router } from '@angular/router';

/**
 * Loyalty Club Page Component
 */
@Component({
  selector: 'packex-loyality-club-page',
  templateUrl: './loyality-club-page.component.html',
  styleUrls: ['./loyality-club-page.component.scss'],
  imports: [
    PageComponent,
    DividerComponent,
    CardComponent,
    ClubFeatureComponent,
    StatusBannerComponent,
    InviteBannerComponent,
    IconComponent,
    ClubTransactionsListComponent,
    LoadingComponent,
    TranslocoPipe,
    StatusOverviewComponent,
  ],
})
export class LoyalityClubPageComponent implements OnInit {
  status = signal<LoyaltyStatusDto | null>(null);
  error = signal<string | null>(null);
  snackBar = inject(SnackBarService);
  levels = signal<LoyaltyStatus[]>([]);

  constructor(
    private readonly loyaltyClubService: LoyaltyClubService,
    private readonly usersService: UserService,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    if (this.usersService.isLoggedIn) {
      this.loadStatusInformation();
    } else {
      this.loadAvailableLevels();
    }
  }

  /**
   * Load status information from the server
   */
  loadStatusInformation(): void {
    this.loyaltyClubService.getStatus().subscribe({
      next: (data) => {
        this.status.set(data);
      },
      error: (error) => {
        this.error.set('Error loading status information');
      },
    });
  }

  loadAvailableLevels(): void {
    this.loyaltyClubService.getAvailableLevels().subscribe({
      next: (data) => {
        this.levels.set(data.reverse());
      },
      error: (error) => {
        this.error.set('Error loading available levels');
      },
    });
  }

  get isLoggedIn() {
    return this.usersService.isLoggedIn;
  }
}
