<packex-page [error]="error()">
  <div class="px-3 px-md-0 d-flex flex-column" #scrollContainer>
    <h3 class="mt-4">
      {{ 'REFERRAL.PAGE.TITLE' | transloco : { referrerName: referrerName() } }}
    </h3>
    <packex-divider></packex-divider>
  </div>

  <packex-card class="w-100 mb-4">
    <h4>Hey,</h4>
    <p>
      jeder Held braucht starke Verbündete – und {{ referrerName() }} glaubt,
      dass du der perfekte Kandidat bist!
    </p>
    <p>
      Willkommen bei PackEx, wo innovative Verpackungslösungen auf
      Nachhaltigkeit treffen. Und als Held deiner eigenen Mission erhältst du
      direkt eine Belohnung:
    </p>

    <p>&nbsp;</p>
    <p>🎁 10 % Rabatt¹ auf deine erste Bestellung!</p>
    <p>&nbsp;</p>
    <p>➤ Deine Mission, falls du sie annehmen solltest:</p>
    <ol>
      <li>Registriere dich über den untenstehenden Link.</li>
      <li>
        Gebe deinen persönlichen 10 %-Gutschein* {{ voucherCode() }} im
        Bestellprozess ein.
      </li>
      <li>
        Erschaffe Verpackungen, die dein Business auf das nächste Level heben.
      </li>
    </ol>
    <packex-button
      type="primary"
      background="secondary-medium"
      (buttonClick)="register()"
      class="w-auto"
      >Jetzt registrieren</packex-button
    >
  </packex-card>
  <packex-card class="w-100 mb-4">
    <h4>Was ist PackEx?</h4>
    <p>PackEx ist der digitale Partner für individuelle Verpackungslösungen.</p>
    <p>
      Wir bieten Unternehmen die Möglichkeit, hochwertige Faltschachteln,
      Etiketten, Beipackzettel und Versandkartons bereits ab Stückzahl 1 zu
      bestellen – schnell, nachhaltig und direkt online. Über unser
      intelligentes Portal konfigurieren Sie Ihre Verpackung einfach selbst,
      laden Druckdaten hoch und erhalten Ihre Produkte innerhalb kürzester Zeit.
      PackEx ermöglicht maximale Flexibilität bei minimalem Aufwand.
    </p>
    <packex-divider />
    <h4>Was macht PackEx aus?</h4>
    <p>
      Wir denken Verpackungslösungen neu – effizient, ressourcenschonend und
      kundenorientiert.
    </p>
    <p>
      PackEx kombiniert Hightech-Produktion mit digitaler
      Benutzerfreundlichkeit:
    </p>
    <ol>
      <li>
        <strong>Sofort online konfigurierbar</strong> – rund um die Uhr, ohne
        lange Wartezeiten
      </li>
      <li>
        <strong>Produktion on demand</strong> – keine Lagerkosten, keine
        Überproduktion
      </li>
      <li>
        <strong>Nachhaltig</strong> – dank digitaler Prozesse und innovativer
        Technologien
      </li>
      <li>
        <strong>Kleinauflagen ab 1 Stück</strong> – ideal für flexible Märkte
        und individuelle Bedürfnisse
      </li>
      <li>
        <strong>Verlässliche Lieferzeiten</strong> – Expressproduktion innerhalb
        von 72 Stunden möglich
      </li>
    </ol>
  </packex-card>
  <div class="disclaimer">
    ¹ Der Gutschein gilt für alle Bestellungen im PackEx-Portal unter
    app.packex.com und ist 1 Jahr gültig ab Erhalt dieser E-Mail. Der Rabatt von
    10% wird auf den Warenwert der Bestellung angerechnet. Nicht gültig für
    bereits bestehende Aufträge. Nur gültig für Kunden und Unternehmen, die
    zuvor noch keine Bestellung bei PackEx aufgegeben haben (Neukunden). Pro
    Kunde ist nur ein Gutschein einlösbar. Der Gutschein kann nicht bar
    ausgezahlt werden. Es gelten die
    <a href="https://packex.com/rechtliches/agb/" target="_blank"
      >allgemeinen Geschäftsbedingungen (AGB)</a
    >
    der PackEx GmbH.
  </div>
</packex-page>
