import { Component, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { DividerComponent } from '../../shared/components/ui/divider/divider.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { PageComponent } from '../../shared/components/ui/page/page.component';
import { CardComponent } from '../../shared/components/ui/card/card.component';
import { ButtonComponent } from '../../shared/components/ui/button/button.component';
import { LoyaltyClubService } from '../club/loyalty-club.service';

@Component({
  selector: 'packex-referral-page',
  templateUrl: './referral-page.component.html',
  styleUrls: ['./referral-page.component.scss'],
  imports: [
    DividerComponent,
    TranslocoPipe,
    PageComponent,
    CardComponent,
    ButtonComponent,
  ],
})
export class ReferralPageComponent implements OnInit {
  error = signal<string | null>(null);
  voucherCode = signal<string | null>(null);
  referrerName = signal<string | null>(null);

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly loyaltyService: LoyaltyClubService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.voucherCode.set(params['code']);
      this.loyaltyService.getReferrar(params['code']).subscribe((result) => {
        this.referrerName.set(result.name);
      });
    });
  }

  register() {
    this.router.navigate(['/user/registration/create']);
  }
}
