@import "styles/variables";

.mobile-menu {
  width: 100%;
  height: calc(100vh - 4rem);
  overflow: auto;
  position: absolute;
  top: $headerHeight;
  right: 0;
  box-shadow: 0 1px 3px 0 rgba(52, 51, 101, 0.16), 0 1px 20px 0 rgba(52, 51, 101, 0.14);

  &.menu {
    ul {
      padding-left: 3.5rem;
      margin: 0;

      > li {
        padding: 0;
        list-style: none;
        height: 3.5rem;
        display: flex;
        align-items: center;

        > a {
          text-decoration: none;
          text-transform: uppercase;
        }
      }
    }
  }
  .products-foldout-toggle {
    margin-bottom: 1rem;

    a {
      height: 3.5rem;
      margin: 0 0 0 1rem;
      text-decoration: none;
      text-transform: uppercase;
      align-items: center;
    }
  }

  .product-foldout-image {
    width: 5rem;
    height: 5rem;
  }
}

packex-mobile-menu {
  .products-foldout {
    width: 100%;
    position: relative !important;
    top: 0 !important;
    left: 0;
    box-shadow: none !important;
    padding: 0 !important;
    flex-direction: column;
    > div {
      width: 100% !important;

      p {
        height: 3.5rem;
      }
    }

    .header {
      height: 3.5rem;
      align-items: center;
      display: flex;
    }
  }
}
