$toolbar-height: 4rem;

packex-cart-flyout {
  position: absolute;
  z-index: 9999;

  mat-toolbar.mat-toolbar {
    z-index: 2;
    width: 25rem !important;
    height: $toolbar-height;
    background: var(--white);
    color: var(--primary-dark);

    button {
      height: auto;
      width: auto;
      padding: 6px;
      margin-right: 0.25rem;
      &:hover {
        background-color: var(--secondary-light);
      }
    }
  }

  .drawer {
    .drawer-sidenav {
      width: 25rem;

      .mat-drawer-inner-container {
        background: var(--white);
      }
    }
  }

  .cart-flyout-content {
    top: 1.5rem;
    height: calc(100% - 1.5rem);
  }

  .product-wrapper {
    flex: 1;
    width: 100%;
    overflow: auto;
    padding: 1rem;
  }

  .price-overview {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0.5rem 1.5rem 0 1.5rem;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(52, 51, 101, 0.16), 0 1px 20px 0 rgba(52, 51, 101, 0.14);
    background-color: var(--white);
    position: relative;
    bottom: 0;
    width: 100%;
    height: auto;
  }

  .mat-mdc-button-persistent-ripple::before {
    content: unset;
  }

  .mat-ripple {
    display: none !important;
  }


}
