<div [class]="clsx('title', status()?.id)">
  {{ 'LOYALTY.HEROES.' + status()?.id | transloco }}
</div>
<p>
  {{
    'LOYALTY.STARTING_AT_POINTS'
      | transloco : { points: status()?.minPoints | formatDecimal }
  }}
</p>
<div>
  <p>{{ 'LOYALTY.ADVANTAGES' | transloco }}:</p>
  <ul>
    @for (feature of status()?.features; track feature.id) {
    <li [innerHtml]="featureNameFor(feature)"></li>
    }
  </ul>
</div>
