import { Component, input } from '@angular/core';
import { ButtonComponent } from '@shared/components/ui/button/button.component';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { SnackBarService } from '@app/shared/components/snackbar/snackbar.service';
import { LoyaltyStatusDto } from '../../loyalty-club.types';

@Component({
  selector: 'packex-invite-banner',
  templateUrl: './invite-banner.component.html',
  styleUrls: ['./invite-banner.component.scss'],
  imports: [ButtonComponent, TranslocoPipe],
})
export class InviteBannerComponent {
  status = input<LoyaltyStatusDto | null>(null);

  constructor(
    private readonly snackbarService: SnackBarService,
    private readonly transloco: TranslocoService,
  ) {}

  get shareData() {
    return {
      title: this.transloco.translate('LOYALTY.PAGE.REFERRAL.SHARE_TITLE'),
      text: this.transloco.translate('LOYALTY.PAGE.REFERRAL.SHARE_BODY', {
        voucher: this.status()?.referralVoucherCode,
      }),
      url:
        document.location.origin +
        '/referral/' +
        this.status()?.referralVoucherCode,
    };
  }

  inviteCustomers() {
    if (navigator.canShare(this.shareData)) {
      navigator.share(this.shareData);
      return;
    }
  }

  get canShare() {
    return navigator.canShare && navigator.canShare(this.shareData);
  }

  copyLink() {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(this.shareData.url);
      this.snackbarService.showSimpleSuccess(
        this.transloco.translate('LOYALTY.PAGE.REFERRAL.LINK_COPIED'),
      );
    } else {
      this.snackbarService.showSimpleError(
        'LOYALTY.PAGE.REFERRAL.LINK_COPY_ERROR',
      );
    }
  }
}
