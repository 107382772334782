<footer [ngClass]="{ 'd-none': !visible, 'd-block': visible }">
  <mat-toolbar class="d-flex justify-content-between flex-column flex-md-row">
    <div
      class="d-flex flex-column flex-md-row justify-content-start align-items-start"
    >
      <div class="d-flex pe-4">
        <a href="/">
          <img
            src="assets/images/packex_logo_footer.svg"
            title="PackEx GmbH"
            alt="PackEx GmbH Logo"
            class="packex-footer-logo"
        /></a>
      </div>
      <div class="d-flex flex-column align-items-start me-3">
        <div class="caption copyright mb-3">
          &copy; {{ currentYear }} {{ 'FOOTER.COPYRIGHT' | transloco }}
        </div>
        <packex-header-language class="mb-3 mb-md-0"></packex-header-language>
      </div>
    </div>
    <div
      class="d-flex align-items-start align-items-md-center w-100 w-md-50 justify-content-between justify-content-md-end align-self-stretch h-100 flex-column flex-md-row"
    >
      <div class="footer-navigation mb-3 mb-md-0">
        <nav class="menu-footer">
          <ul class="d-flex flex-wrap">
            <li>
              <a
                [href]="'LINKS.FEEDBACK' | transloco"
                target="_blank"
                class="caption link text-primary-dark"
                >{{ 'FOOTER.FEEDBACK' | transloco }}</a
              >
            </li>
            <li>
              <a
                (click)="openContactDialog($event)"
                class="caption link text-primary-dark"
                >{{ 'FOOTER.CONTACT' | transloco }}</a
              >
            </li>
            <li>
              <a
                [href]="'LINKS.IMPRINT' | transloco"
                target="_blank"
                class="caption link text-primary-dark"
                >{{ 'FOOTER.IMPRINT' | transloco }}</a
              >
            </li>
            <li>
              <a
                [href]="'LINKS.FAQ' | transloco"
                target="_blank"
                class="caption link text-primary-dark"
                >{{ 'FOOTER.FAQ' | transloco }}</a
              >
            </li>
            <li>
              <a
                [href]="'LINKS.AVB' | transloco"
                target="_blank"
                class="caption link text-primary-dark"
                >{{ 'FOOTER.AVB' | transloco }}</a
              >
            </li>
            <li>
              <a
                [href]="'LINKS.DATA_PRIVACY' | transloco"
                target="_blank"
                class="caption link text-primary-dark"
                >{{ 'FOOTER.DATA_PRIVACY' | transloco }}</a
              >
            </li>
          </ul>
        </nav>
      </div>
      <div class="d-flex h-100 align-items-end align-items-sm-center">
        <a [href]="'LINKS.FACEBOOK' | transloco" class="me-3" target="_blank">
          <packex-icon icon="facebook" size="s"></packex-icon>
        </a>
        <a [href]="'LINKS.LINKEDIN' | transloco" class="me-3" target="_blank">
          <packex-icon icon="linkedIn" size="s"></packex-icon>
        </a>
        <a [href]="'LINKS.INSTAGRAM' | transloco" class="me-3" target="_blank">
          <packex-icon icon="instagram" size="s"></packex-icon>
        </a>
      </div>
    </div>
  </mat-toolbar>
</footer>
