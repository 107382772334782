<packex-page [error]="error()">
  <div class="px-3 px-md-0 d-flex flex-column" #scrollContainer>
    <h3 class="mt-4">{{ 'LOYALTY.PAGE.TITLE' | transloco }}</h3>
    <packex-divider></packex-divider>
  </div>

  @if(isLoggedIn) { @if (status()) { @if (status()?.disabled) {
  <packex-card class="w-100 mb-4">
    <div class="disabled">
      <p>
        {{ 'LOYALTY.DISABLED.TITLE' | transloco }}<br />{{
          'LOYALTY.DISABLED.CONTACT' | transloco
        }}
      </p>
    </div>
  </packex-card>
  } @else {
  <packex-card class="w-100 mb-4">
    <div class="advantages">
      <h4>{{ 'LOYALTY.PAGE.POINTS_AND_ADVANTAGES' | transloco }}</h4>
      <p>{{ 'LOYALTY.PAGE.INTRO' | transloco }}</p>
      <packex-status-banner [status]="status()"></packex-status-banner>
      <div class="features">
        @for (feature of status()?.features; track feature.id) {
        <packex-club-feature
          [feature]="feature"
          [status]="status()"
        ></packex-club-feature>
        }
      </div>
      <div class="info">
        <packex-icon icon="info_circle" color="primary"></packex-icon>
        {{ 'LOYALTY.PAGE.REDEEM_INFO' | transloco }}
      </div>
      <packex-invite-banner [status]="status()"></packex-invite-banner>
      <packex-club-transactions-list></packex-club-transactions-list>
    </div>
  </packex-card>

  <packex-card class="w-100 mb-4 info">
    <h4>PackEx Helden-Club – Dein exklusives Treueprogramm</h4>
    <p>
      Mehr bestellen, mehr profitieren – unser Treueprogramm belohnt dich für
      deine Bestellungen und sorgt dafür, dass du immer den besten Service
      bekommst. Erfahre hier alles über den PackEx Helden-Club und wie du davon
      profitieren kannst!
    </p>
    <h5>Was ist der PackEx Helden-Club?</h5>
    <p>
      Der Helden-Club ist unser exklusives Loyalitätsprogramm für PackEx-Kunden.
      Mit einem einfachen Punktesystem sammelst du automatisch bei jeder
      Bestellung Punkte und sicherst dir attraktive Rabatte und Vorteile. Das
      Programm ist kostenlos und bietet dir viele exklusive Möglichkeiten.
    </p>
    <h5>So funktioniert der Helden-Club</h5>
    <ul>
      <li>1 € Umsatz = 1 Punkt</li>
      <li>Punkte bleiben 12 Monate gültig.</li>
      <li>Je mehr Punkte du sammelst, desto höher steigst du auf.</li>
      <li>
        Dein Status bleibt für 12 Monate bestehen, solange du die erforderlichen
        Punkte hältst.
      </li>
      <li>Exklusiv 500 Willkommenspunkte bei Erstregistrierung.</li>
      <li>1.000 Punkte für jeden erfolgreich geworbenen Neukunden.</li>
      <li>Exklusive Rabatte und Vorteile für treue Kunden!</li>
    </ul>
  </packex-card>

  <packex-status-overview [status]="status()" />
  <div class="disclaimer">
    {{ 'LOYALTY.PAGE.DISCLAIMER' | transloco }}
  </div>
  } } @else {
  <packex-loading-component></packex-loading-component>
  } } @else {
  <packex-card class="w-100 mb-4 info">
    <h4>PackEx Helden-Club – Dein exklusives Treueprogramm</h4>
    <p>
      Mehr bestellen, mehr profitieren – unser Treueprogramm belohnt dich für
      deine Bestellungen und sorgt dafür, dass du immer den besten Service
      bekommst. Erfahre hier alles über den PackEx Helden-Club und wie du davon
      profitieren kannst!
    </p>
    <h5>Was ist der PackEx Helden-Club?</h5>
    <p>
      Der Helden-Club ist unser exklusives Loyalitätsprogramm für PackEx-Kunden.
      Mit einem einfachen Punktesystem sammelst du automatisch bei jeder
      Bestellung Punkte und sicherst dir attraktive Rabatte und Vorteile. Das
      Programm ist kostenlos und bietet dir viele exklusive Möglichkeiten.
    </p>
    <h5>So funktioniert der Helden-Club</h5>
    <ul>
      <li>1 € Umsatz = 1 Punkt</li>
      <li>Punkte bleiben 12 Monate gültig.</li>
      <li>Je mehr Punkte du sammelst, desto höher steigst du auf.</li>
      <li>
        Dein Status bleibt für 12 Monate bestehen, solange du die erforderlichen
        Punkte hältst.
      </li>
      <li>Exklusiv 500 Willkommenspunkte bei Erstregistrierung.</li>
      <li>1.000 Punkte für jeden erfolgreich geworbenen Neukunden.</li>
      <li>Exklusive Rabatte und Vorteile für treue Kunden!</li>
    </ul>
  </packex-card>
  <packex-status-overview [availableStatuses]="levels()" />
  <div class="disclaimer">
    {{ 'LOYALTY.PAGE.DISCLAIMER' | transloco }}
  </div>
  }
</packex-page>
